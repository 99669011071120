import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CurrencySelect from '../../../CurrencySelect';

import { updateCurrencyRequest } from '../../../HomePage/actions';

import { currentTokenVersionSelector, paymentMethodBrandLogoUrlSelector } from '../../selectors';

import i18n from '../../../../i18n';

import './styles.scss';

const PaymentOptionInfo = (props) => {
  const { currentTokenVersion, paymentMethodBrandLogoUrl } = props;

  if (!currentTokenVersionSelector) {
    return null;
  }

  const { cardNumber, expiresOn } = currentTokenVersion;

  return (
    <div className="payment-option-info-container">
      <div className="credit-card-container">
        <div className="credit-card-number">
          <div className="credit-card-label-container">
            {paymentMethodBrandLogoUrl ? (
              <div className="credit-card-icon">
                <img
                  src={paymentMethodBrandLogoUrl}
                  alt=""
                />
              </div>
            ) : null}
            <span className="credit-card-label">{i18n.t('creditCardLabel')}</span>
          </div>
          <div className="credit-card-number">{cardNumber}</div>
        </div>
        <div className="credit-card-expired">
          <span className="expired-on-label">{i18n.t('endsOnLabel')}</span>
          <span className="expired-on-value">{expiresOn}</span>
        </div>
        <div className="card-info-currency-container">
          <span className="currency-label">{i18n.t('curencyLabel')}</span>
          <CurrencySelect showLabel={false} />
        </div>
      </div>
    </div>
  );
};

PaymentOptionInfo.propTypes = {
  currentTokenVersion: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(null),
  ]).isRequired,
  paymentMethodBrandLogoUrl: PropTypes.string,
};

PaymentOptionInfo.defaultProps = {
  paymentMethodBrandLogoUrl: '',
};

const mapStateToProps = createStructuredSelector({
  currentTokenVersion: currentTokenVersionSelector,
  paymentMethodBrandLogoUrl: paymentMethodBrandLogoUrlSelector,
});

const mapDispatchToProps = (dispatch) => ({
  changeCurrency: (newCurrency) => dispatch(updateCurrencyRequest(newCurrency)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptionInfo);
